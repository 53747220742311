export const links = [
  {
    name: "Services",
    submenu: true,
    sublinks: [
      {
        Head: "",
        sublink: [
          { name: "General Services", link: "/generalservice" },
          { name: "Marine support services", link: "/marine" },
          { name: "Construction and Remodelling services", link: "/construction" },
          { name: "Aviation services", link: "/aviation" },
          // { name: "Diving services", link: "dss" },
        ],
      },
      // {
      //   Head: "",
      //   sublink: [
      //     { name: "Waste management services", link: "wms" },
      //     { name: "Rig and vessel maintenance", link: "rvm" },
      //     { name: "Dredging services", link: "ds" },
      //     { name: "Construction and remodelling services", link: "dds" },
      //   ],
      // },
    ],
  },
];
