import React from 'react'
import Header from '../components/Header/Header'

const ServicesSection = () => {
  return (
    <div className='w-full bg-[rgb(11,27,64)] md:-mt-24'>
        <Header/>
    </div>
  )
}

export default ServicesSection